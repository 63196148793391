interface CardProps {
  children: React.ReactNode;
  onClick?: () => void;
}

const Card: React.FC<CardProps> = (props) => {
  const { children, onClick } = props;

  return (
    <div
      onClick={onClick}
      style={{
        height: "fit-content",
      }}
      className="mx-auto cursor-pointer bg-white hover:scale-[1.02] hover:shadow-lg transition ease-in-out duration-150 flex flex-col px-6 py-6 rounded-[24px] border border-gray-100 justify-start items-center w-72  shadow-md"
    >
      {children}
    </div>
  );
};

export default Card;
