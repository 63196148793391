import { ApolloClient, gql, InMemoryCache } from "@apollo/client";

export const clientFactory = (uri: string) =>
  new ApolloClient({
    uri,
    cache: new InMemoryCache(),
  });

export const seedsQuery = (first = 1_000) => gql`
  {
    seeds(first: ${first}) {
      id
      background
      body
      accessory
      head
      glasses
    }
  }
  `;
