import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import BrandSpinner from "../../components/BrandSpinner";
import NounWithStats from "../../components/Noun";
import { seedsQuery } from "../../wrappers/subgraph";
import { NounRarityInfo, NounSeed } from "../../wrappers/types";
import classes from "./RarityStats.module.css";
import clsx from "clsx";

const RarityStats = () => {
  const [rarityData, setRarityData] = useState<Map<
    number,
    NounRarityInfo
  > | null>(null);

  useEffect(() => {
    const fetchRarityData = async () => {
      const response = await fetch(
        "https://7tedfll1w4.execute-api.us-east-2.amazonaws.com/Prod/rarity-stats"
      );
      if (!response.ok) {
        return;
      }

      const data = await response.json();
      try {
        // setRarityData(data.nouns_stats);
        const rarityMap = new Map<number, NounRarityInfo>();
        data.noun_stats.forEach((payload: any) => {
          rarityMap.set(payload.noun_id, {
            headFreqCount: payload.head_freq_count,
            glassesFreqCount: payload.glasses_freq_count,
            backgroundFreqCount: payload.background_freq_count,
            bodyFreqCount: payload.body_freq_count,
            accessoryFreqCount: payload.accessory_freq_count,
            isFirstAccessory: payload.is_fisrt_accessory,
            isFirstBackground: payload.is_first_head,
            isFirstBody: payload.is_first_body,
            isFirstGlasses: payload.is_first_glasses,
            isFirstHead: payload.is_first_head,
          } as NounRarityInfo);
        });

        setRarityData(rarityMap);

        // setTotalSupply(data.total_supply);
      } catch (e) {
        console.log(e);
      }
    };

    if (!rarityData) {
      fetchRarityData();
    }
  });

  const { data, loading, error } = useQuery(seedsQuery());

  console.log(data);

  if (loading || rarityData === null) {
    return (
      <div
        className={clsx(
          "w-screen min-h-screen bg-gray-100",
          classes.background
        )}
      >
        <div className="flex justify-center items-center pt-6 w-full bg-gray-100">
          <h1
            style={{
              fontFamily: "Londrina Solid",
            }}
            className="text-6xl text-center"
          >
            Nouns Rarity Stats
          </h1>
        </div>
        <div className="text-md font-medium text-center w-full bg-gray-100">
          <p className="font-semibold text-lg">
            By: brianj (
            <a
              className="text-[#d63c5e] cursor-pointer hover:underline transition ease-in-out 125"
              href="https://twitter.com/pbrianandj"
              target="_blank"
              rel="noopener noreferrer"
            >
              @pbrianandj
            </a>
            )
          </p>
          <p className="text-md">
            Like this page? Please consider giving me a follow 🙏{" "}
          </p>
        </div>
        <div className="flex justify-center mt-6">
          <BrandSpinner />
        </div>
      </div>
    );
  }

  if (error) {
    return <div>Error</div>;
  }

  return (
    <div
      className={clsx("w-screen min-h-screen bg-gray-100", classes.background)}
    >
      <div className="flex justify-center items-center pt-6 w-full bg-gray-100">
        <h1
          style={{
            fontFamily: "Londrina Solid",
          }}
          className="text-6xl text-center"
        >
          Nouns Rarity Stats
        </h1>
      </div>
      <div className="text-md font-medium text-center w-full bg-gray-100">
        <p className="font-semibold text-lg">
          By: brianj (
          <a
            className="text-[#d63c5e] cursor-pointer hover:underline transition ease-in-out 125"
            href="https://twitter.com/pbrianandj"
            rel="noopener noreferrer"
            target="_blank"
          >
            @pbrianandj
          </a>
          )
        </p>
        <p className="text-md">
          Like this page? Please consider giving me a follow 🙏{" "}
        </p>
      </div>

      <div className="mx-auto w-11/12 grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-6">
        {data &&
          data.seeds
            .slice(0, data.seeds.length)
            .sort((a: any, b: any) => parseInt(a.id) - parseInt(b.id))
            .reverse()
            .map((payload: any, index: number) => {
              return (
                <NounWithStats
                  key={index}
                  nounId={payload.id}
                  seed={
                    {
                      background: payload.background,
                      body: payload.body,
                      head: payload.head,
                      glasses: payload.glasses,
                      accessory: payload.accessory,
                    } as NounSeed
                  }
                  stats={rarityData.get(parseInt(payload.id))}
                />
              );
            })}
      </div>
    </div>
  );
};

export default RarityStats;
