import React from "react";
import { NounRarityInfo, NounSeed } from "../../wrappers/types";
//import BrandSpinner from "../BrandSpinner";
import Card from "../Card";
import NounFromChain from "../NounFromChain";

interface NounProps {
  nounId: number;
  seed: NounSeed;
  stats: NounRarityInfo | undefined;
}

const getTraitCopy = (stats: NounRarityInfo | undefined) => {
  if (stats === undefined) {
    return "";
  }

  const newTraits = [];
  if (stats.isFirstAccessory) {
    newTraits.push("Accessory");
  }

  if (stats.isFirstHead) {
    newTraits.push("Head");
  }

  if (stats.isFirstBody) {
    newTraits.push("Body");
  }

  if (stats.isFirstGlasses) {
    newTraits.push("Glasses");
  }

  return newTraits.join(", ");
};

const getTraitTitleCopy = (stats: NounRarityInfo | undefined) => {
  if (stats === undefined) {
    return "";
  }

  const newTraits = [];
  if (stats.isFirstAccessory) {
    newTraits.push("Accessory");
  }

  if (stats.isFirstHead) {
    newTraits.push("Head");
  }

  if (stats.isFirstBody) {
    newTraits.push("Body");
  }

  if (stats.isFirstGlasses) {
    newTraits.push("Glasses");
  }

  return newTraits.length === 1 ? "Trait" : "Traits";
};

const NounWithStats: React.FC<NounProps> = (props) => {
  const { nounId, seed, stats } = props;
  //const [isLoading, setIsLoading] = useState<boolean>(false);
  //const [isError, setIsError] = useState<boolean>(false);
  //const [imageData, setImageData] = useState<string | null>(null);

 /* useEffect(() => {
    const fetchImage = async () => {
      let response;
      try {
        response = await fetch(
          //`https://api.cloudnouns.com/v1/pfp?seed=${seed.background},${seed.body},${seed.accessory},${seed.head},${seed.glasses}`
           `https://noun.pics/${nounId}`,
          {

                mode: 'no-cors', // no-cors, *cors, same-origin

          }
        );
      } catch (e) {
        setIsError(true);
        setIsLoading(false);
        console.log("ERRRRRRRRRROR", e);
        return;
      }

      if (!response.ok) {
        console.log("ERROR", response.status);
        setIsError(true);
        setIsLoading(false);
        return;
      }

      let imageData;
      try {
        imageData = await response.text();
        setImageData(imageData);
        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setIsError(true);
        return;
      }
      setIsLoading(false);
    };

    if (isLoading) {
      fetchImage();
    }
  }, [nounId, isLoading, seed]);*/

  /*if (isLoading) {
    return (
      <Card>
        <BrandSpinner />
      </Card>
    );
  }*/

  return (
    <Card
      onClick={() => window.open(`https://nouns.wtf/noun/${nounId}`, "_blank")}
    >
      <div
        className="text-3xl mb-3"
        style={{
          fontFamily: "Londrina Solid",
        }}
      >
        Noun {nounId}
      </div>
      {false ? (
        <NounFromChain nounId={nounId} />
      ) : (
        <img
          className="h-48 w-48 rounded-[8px]"
          alt="Noun"
          //src={`data:image/svg+xml;utf8,${encodeURIComponent(imageData ?? "")}`}
          src={`https://noun.pics/${nounId}`}
        />
      )}

      <div className="w-48 mt-2">
        {(stats?.isFirstHead ||
          stats?.isFirstAccessory ||
          stats?.isFirstGlasses ||
          stats?.isFirstBody) && (
          <div className="flex flex-col font-medium mt-4 border-b border-gray-200 pb-3">
            <p className="font-bold">✨ New {getTraitTitleCopy(stats)} ✨</p>
            {getTraitCopy(stats)}
          </div>
        )}
      </div>
      <div className="mt-4 font-medium w-48">
        <p>
          {" "}
          <span className="font-bold">Head: </span> {seed.head}{" "}
          <span className="text-sm opacity-50">
            ({stats?.headFreqCount}{" "}
            {stats?.headFreqCount === 1 ? "Noun has" : "Nouns have"} this trait)
          </span>
        </p>
        <p>
          <span className="font-bold">Body: </span> {seed.body}{" "}
          <span className="opacity-50 text-sm">
            ({stats?.bodyFreqCount}{" "}
            {stats?.bodyFreqCount === 1 ? "Noun has" : "Nouns have"} this trait)
          </span>
        </p>
        <p>
          <span className="font-bold">Glasses: </span> {seed.glasses}{" "}
          <span className="opacity-50 text-sm">
            ({stats?.glassesFreqCount}{" "}
            {stats?.glassesFreqCount === 1 ? "Noun has" : "Nouns have"} this
            trait)
          </span>
        </p>
        <p>
          <span className="font-bold">Accessory: </span> {seed.accessory}{" "}
          <span className="text-sm opacity-50">
            ({stats?.accessoryFreqCount}{" "}
            {stats?.accessoryFreqCount === 1 ? "Noun has" : "Nouns have"} this
            trait)
          </span>
        </p>
        <p>
          <span className="font-bold">Background: </span> {seed.background}{" "}
          <span className="text-sm opacity-50">
            ({stats?.backgroundFreqCount}{" "}
            {stats?.backgroundFreqCount === 1 ? "Noun has" : "Nouns have"} this
            trait)
          </span>
        </p>
      </div>
    </Card>
  );
};

export default NounWithStats;
