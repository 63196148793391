import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloProvider } from "@apollo/client";
import { clientFactory } from "./wrappers/subgraph";
import { ChainId, Config, DAppProvider } from "@usedapp/core";

const client = clientFactory(
  "https://api.thegraph.com/subgraphs/name/nounsdao/nouns-subgraph"
);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const config: Config = {
  readOnlyChainId: ChainId.Mainnet,
  readOnlyUrls: {
    [ChainId.Mainnet]:
      "https://mainnet.infura.io/v3/f74ad8aacd6a44a7a52f4db811276bcd",
  },
};

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <DAppProvider config={config}>
        <App />
      </DAppProvider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
