import { useContractCall } from "@usedapp/core";
import { BigNumber as EthersBN, utils } from "ethers";
import NounsTokenABI from "./nounsTokenABI.json";

interface NounToken {
  name: string;
  description: string;
  image: string;
}

export interface INounSeed {
  accessory: number;
  background: number;
  body: number;
  glasses: number;
  head: number;
}

const abi = new utils.Interface(NounsTokenABI);

export const useNounToken = (nounId: EthersBN) => {
  const [noun] =
    useContractCall<[string]>({
      abi,
      address: "0x9c8ff314c9bc7f6e59a9d9225fb22946427edc03",
      method: "dataURI",
      args: [nounId],
    }) || [];

  if (!noun) {
    return;
  }

  const nounImgData = noun.split(";base64,").pop() as string;
  const json: NounToken = JSON.parse(atob(nounImgData));
  return json.image;
};
